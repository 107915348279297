<!-- ---- Start Navbar ---- -->
<nav class="navbar navbar-expand-lg navbar-dark bg-dark fixed-top">
  <div class="container">
    <a class="navbar-brand" href="#"><img src="../../../assets/logo.png" style="max-height: 50px" alt=""></a>
    <span style="color: #ffffff; font-weight: bold">Yassine Gabsi</span>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNav"
      aria-controls="navbarNav"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a class="nav-link" href="#slider">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#services">Services</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#resume">Career</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#skills">Skills</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#projects">Portfolio</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#testimonials">Testimonials</a>
        </li>
        <!-- Drop Down-->
<!--        <li class="nav-item dropdown">-->
<!--          <a-->
<!--            class="nav-link "-->
<!--            href="#"-->
<!--            id="navbarDropdown"-->
<!--            role="button"-->
<!--            data-toggle="dropdown"-->
<!--            aria-haspopup="true"-->
<!--            aria-expanded="false">-->
<!--            Projects-->
<!--            <span class="fa fa-angle-down"></span>-->
<!--          </a>-->
<!--          <div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
<!--            <a class="dropdown-item" href="#">Blog List</a>-->
<!--            <a class="dropdown-item" href="#">Single Post</a>-->
<!--          </div>-->
<!--        </li>-->
        <!-- Drop Down-->
        <li class="nav-item">
          <a class="nav-link" href="#contact">Contact</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- ---- End Navbar ---- -->
