<section class="some-facts">
  <div class="container text-center">
    <div class="row">
      <!-- Box-1 -->
      <div class="col-lg-3 col-sm-6 mt-2" >
        <div class="items">
          <i class="fas fa-users fa-3x"></i>
          <h3>
            <span class="counter">5</span></h3>
          <div class="line mx-auto"></div>
          <h4>Happy Clients</h4>
        </div>
      </div>
      <!-- Box-2 -->
      <div class="col-lg-3 col-sm-6 mt-2" >
        <div class="items">
          <i class="fas fa-briefcase fa-3x"></i>
          <h3>
            <span class="counter">12</span></h3>
          <div class="line mx-auto"></div>
          <h4>Projects Compleated</h4>
        </div>
      </div>
      <!-- Box-3 -->
      <div class="col-lg-3 col-sm-6 mt-2" >
        <div class="items">
          <i class="fas fa-file-alt fa-3x"></i>
          <h3>
            <span class="counter">3</span></h3>
          <div class="line mx-auto"></div>
          <h4>Certificates</h4>
        </div>
      </div>
      <!-- Box-4 -->
      <div class="col-lg-3 col-sm-6 mt-2" >
        <div class="items">
          <i class="fas fa-medal fa-3x"></i>
          <h3>
            <span class="counter">3</span></h3>
          <div class="line mx-auto"></div>
          <h4>Awards</h4>
        </div>
      </div>
    </div>
  </div>
</section>
