<section class="blog" id="blog">
  <div class="container">
    <h2 class="text-center">Pentbox Python</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="col-12">
        <div class="box">
          <div class="image">
            <!--            <img src="../../../../assets/images/projects/elbaladiya.jpg" alt="">-->
            <!--            <a href="" class="cate">Web</a>-->
                        <h3 style="padding: 0 20px">
                          <a href="https://github.com/YassineGabsi/pentbox-python" target="_blank" class="see-link" style="font-size: 20px">Visit</a>
                        </h3>
          </div>
          <div class="text">
            <h3><span>Pentbox Python</span>
              <!--              <span class="see-link">Visit</span>-->
            </h3>
            <i>01/2021 - 03/2021</i>
            <br>
            <br>

            <div class="col-md-8 mx-auto mb-5">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>

                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/pentbox1.jpg" alt="First slide">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

            </div>

            <p>Pentbox Python is a cryptography tool that allows encoding, decoding, hashing, cracking hashes and asymmetric and
              symmetric encryption/decryption.
              <br>
              <br>
            </p>


            <div class="project-tag">Python</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
