import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomePageComponent} from './pages/home-page/home-page.component';

const routes: Routes = [
  {
    path: 'projects',
    loadChildren: () => import('./pages/project-pages/project-pages.module')
      .then(m => m.ProjectPagesModule),
  },
  {
    path: '',
    component: HomePageComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
