import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-elbaladiya',
  templateUrl: './elbaladiya.component.html',
  styleUrls: ['./elbaladiya.component.css']
})
export class ElbaladiyaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.carousel').swipe({

      swipe: (event: any, direction: any, distance: any, duration: any, fingerCount: any, fingerData: any) => {

        if (direction === 'left') {
          $(this).carousel('next');
        }
        if (direction === 'right') {
          $(this).carousel('prev');
        }
      },
      allowPageScroll: 'vertical'

    });
  }

}
