import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-project-pages',
  templateUrl: './project-pages.component.html',
  styleUrls: ['./project-pages.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectPagesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
