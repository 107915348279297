<section class="blog" id="blog">
  <div class="container">
    <h2 class="text-center">MedExtract</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="col-12">
        <div class="box">
          <div class="image">
            <!--            <img src="../../../../assets/images/projects/elbaladiya.jpg" alt="">-->
            <!--            <a href="" class="cate">Web</a>-->
<!--            <h3 style="padding: 0 20px">-->
<!--              <a href="https://github.com/YassineGabsi/HackOver-Front" target="_blank" class="see-link" style="font-size: 20px">Visit</a>-->
<!--            </h3>-->
          </div>
          <div class="text">
            <h3><span>MedExtract</span>
              <!--              <span class="see-link">Visit</span>-->
            </h3>
            <i>01/2021 - 04/2021</i>
            <br>
            <br>

            <div class="col-md-8 mx-auto mb-5">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/medextract1.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/medextract2.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/medextract3.jpg" alt="First slide">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

            </div>

            <p>The aim of the project is the automation of the analysis of medical documents.
              The project consists of creating a machine learning model for recognizing and
              integrating medical terms in a web application that allows users to extract and classify medical terms
              of their documents. The project is in collaboration with LGC group.
              <br>
              <br>
              - The SaaS platform allows users to import their documents, analyze them and view
              the results
              <br>
              - Provide an authentication, usage tracking and payment system depending on the model "Pay as you go"
              <br>
              - Create a machine learning model for medical term recognition and
              classification according to ICD 10
              <br>
              <br>
              So my tasks in this project was dealing with the Frontend and the Backend sides:
              <br>
              <br>
              • Prototyping the application using Adobe XD and Adobe Photoshop.
<!--              <a href="https://xd.adobe.com/view/1229a113-cc07-446c-9e29-19ab08efc283-477a/" target="_blank"><b>(Click here to see prototype)</b></a>-->
              <br>
              • Implement the prototype in an Angular application.
              <br>
              • Participating in the creation of the backend application using Django Web Framework.
              <br>
              • Linking both Frontend and Backend parts.
              <br>
            </p>


            <div class="project-tag">Angular</div>
            <div class="project-tag">Django</div>
            <div class="project-tag">Git / Github</div>
            <div class="project-tag">CSS</div>
            <div class="project-tag">Adobe XD</div>
            <div class="project-tag">Adobe Photoshop</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
