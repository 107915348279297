import {Component, OnInit} from '@angular/core';
declare var particlesJS: any;
declare var $: any;

@Component({
  selector: 'app-top-section',
  templateUrl: './top-section.component.html',
  styleUrls: ['./top-section.component.css']
})
export class TopSectionComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {

    $(() => {
      $('.animated-headlines').animatedHeadline({
        animationType: 'type',
      });
    });

    particlesJS.load('particles-js', 'assets/js/particles.json', () => {
      console.log('callback - particles.js config loaded');
    });
  }

  downloadMyFile(): void{
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', '../../../assets/cv.pdf');
    console.log(link);
    link.setAttribute('download', `cv.pdf`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
