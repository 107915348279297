import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ProjectPagesComponent} from './project-pages.component';
import { ElbaladiyaComponent } from './elbaladiya/elbaladiya.component';
import { MedextractComponent } from './medextract/medextract.component';
import { HackoverComponent } from './hackover/hackover.component';
import { WebipieComponent } from './webipie/webipie.component';
import { PfaComponent } from './pfa/pfa.component';
import { PentboxComponent } from './pentbox/pentbox.component';
import { NimgameComponent } from './nimgame/nimgame.component';
import { JciComponent } from './jci/jci.component';
import { PortfolioComponent } from './portfolio/portfolio.component';
import {RouterModule} from '@angular/router';
import {ProjectPagesRoutingModule} from './project-pages-routing.module';



@NgModule({
  declarations: [
    ProjectPagesComponent,
    ElbaladiyaComponent,
    MedextractComponent, HackoverComponent,
    WebipieComponent,
    PfaComponent,
    PentboxComponent,
    NimgameComponent,
    JciComponent,
    PortfolioComponent
  ],

  exports: [
    ProjectPagesComponent,
    ElbaladiyaComponent
  ],
  imports: [
    CommonModule,
    ProjectPagesRoutingModule,
    RouterModule
  ]
})
export class ProjectPagesModule { }
