<section class="blog" id="blog">
  <div class="container">
    <h2 class="text-center">NIM Game</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="col-12">
        <div class="box">
          <div class="image">
            <!--            <img src="../../../../assets/images/projects/elbaladiya.jpg" alt="">-->
            <!--            <a href="" class="cate">Web</a>-->
            <!--            <h3 style="padding: 0 20px">-->
            <!--              <a href="https://github.com/YassineGabsi/HackOver-Front" target="_blank" class="see-link" style="font-size: 20px">Visit</a>-->
            <!--            </h3>-->
          </div>
          <div class="text">
            <h3><span>NIM Game</span>
              <!--              <span class="see-link">Visit</span>-->
            </h3>
            <i>12/2020</i>
            <br>
            <br>

            <div class="col-md-8 mx-auto mb-5">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>

                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/nim1.jpg" alt="First slide">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

            </div>

            <p>Nim is a mathematical game of strategy in which two players take turns removing (or "nimming") objects
              from distinct heaps or piles. On each turn, a player must remove at least one object, and may remove any
              number of objects provided they all come from the same heap or pile. Depending on the version being
              played, the goal of the game is either to avoid taking the last object or to take the last object.
              <br>
              <br>
              This project is an AI script that simulates the nim game, where the two players are the system and the user.
            </p>


            <div class="project-tag">Python</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
