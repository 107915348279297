<section class="skills-section" id="skills">
  <h2 class="text-center font-weight-bold pb-4" style="color: var(--blue-color);">Skills</h2>
  <div class="row col-12" >
    <div class="col-md-6 px-4">
      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-js-square mx-2 fa-lg"></i></span>
          <span>Javascript</span>
          <span style="float: right">90%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-js progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>
      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-css3 mx-2 fa-lg"></i></span>
          <span>CSS</span>
          <span style="float: right">90%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-css progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-angular mx-2 fa-lg"></i></span>
          <span>Angular</span>
          <span style="float: right">90%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-angular progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-react mx-2 fa-lg"></i></span>
          <span>ReactJS</span>
          <span style="float: right">80%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-react progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-node-js mx-2 fa-lg"></i></span>
          <span>NodeJS</span>
          <span style="float: right">80%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-node progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-laravel mx-2 fa-lg"></i></span>
          <span>Laravel</span>
          <span style="float: right">80%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-node progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
           <span><img class="img-fluid mx-2"
                      style="max-height: 25px" src="../../../assets/images/logos/nestjs.png" alt=""></span>
          <span>NestJS</span>
          <span style="float: right">60%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-nest progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>


      <div>
        <div class="progress-bar-label col-12">
           <span><img class="img-fluid mx-2"
                      style="max-height: 25px" src="../../../assets/images/logos/django.png" alt=""></span>
          <span>Django</span>
          <span style="float: right">55%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-django progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>
    </div>



    <div class="col-md-6 px-4">
      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-python mx-2 fa-lg"></i></span>
          <span>Python</span>
          <span style="float: right">75%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-python progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-java mx-2 fa-lg"></i></span>
          <span>Java</span>
          <span style="float: right">75%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-java progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
           <span><img class="img-fluid mx-2"
                      style="max-height: 25px" src="../../../assets/images/logos/cplusplus.png" alt=""></span>
          <span>C/C++</span>
          <span style="float: right">65%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-c progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>


      <div>
        <div class="progress-bar-label col-12">
           <span><img class="img-fluid mx-2"
                      style="max-height: 25px" src="../../../assets/images/logos/mongo.png" alt=""></span>
          <span>MongoDB</span>
          <span style="float: right">70%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-mongo progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-github mx-2 fa-lg"></i></span>
          <span>Git / Github / Gitlab</span>
          <span style="float: right">80%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-git progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><img class="img-fluid mx-2"
                     style="max-height: 25px" src="../../../assets/images/logos/firebase.png" alt=""></span>
          <span>Firebase</span>
          <span style="float: right">60%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-firebase progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

      <div>
        <div class="progress-bar-label col-12">
          <span><i class="fab fa-aws mx-2 fa-lg"></i></span>
          <span>AWS</span>
          <span style="float: right">60%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-firebase progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>


      <div>
        <div class="progress-bar-label col-12">
          <span><img class="img-fluid mx-2"
                     style="max-height: 25px" src="../../../assets/images/logos/ps.png" alt=""></span>          <span>Adobe Photoshop / Adobe XD</span>
          <span style="float: right">80%</span>
        </div>
        <div class="progress-container progress-bar-wrapper">
          <div class="progress progress-striped active">
            <div class="progress-bar-xd progress-bar progress-bar-success" style="width: 0 "></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
