import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-nimgame',
  templateUrl: './nimgame.component.html',
  styleUrls: ['./nimgame.component.css']
})
export class NimgameComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
