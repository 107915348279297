<section class="services" id="services">
  <h2 class="text-center vivify fadeIn delay-350">Services</h2>
  <div class="container">
    <div class="row">
      <!-- Box-1 -->
      <div class="col-md-6 col-lg-4"  data-aos-delay="300">
        <div class="box">
          <span class="fa fa-cog fa-2x"></span>
          <h3>Creative Design</h3>
          <p>My experience with UI/UX design gave me the possibility to create creative and unique results. </p>
        </div>
      </div>
      <!-- Box-2 -->
      <div class="col-md-6 col-lg-4"  data-aos-delay="600">
        <div class="box">
          <span class="fa fa-crop fa-2x"></span>
          <h3>Clean Code</h3>
          <p>My several years of coding and software development made me able to write clean, extensible, and readable code. </p>
        </div>
      </div>
      <!-- Box-3 -->
      <div class="col-md-6 col-lg-4"  data-aos-delay="600">
        <div class="box">
          <span class="fa fa-cubes fa-2x"></span>
          <h3>Responsive Design</h3>
          <p>I can create a product that could be accessible from different devices (Desktop / Mobile / Tablet ...)</p>
        </div>
      </div>
      <!-- Box-4 -->
      <div class="col-md-6 col-lg-4"  data-aos-delay="600">
        <div class="box">
          <span class="fa fa-stopwatch fa-2x"></span>
          <h3>On Time Delivery</h3>
          <p>My duty is to deliver the product on time, and this will be guaranteed while working with me</p>
        </div>
      </div>
      <!-- Box-5 -->
      <div class="col-md-6 col-lg-4"  data-aos-delay="600">
        <div class="box">
          <span class="fa fa-code fa-2x"></span>
          <h3>Client satisfaction</h3>
          <p>One of my important concerns is to reach the satisfaction of my clients, and to deliver the desired results. </p>
        </div>
      </div>
      <!-- Box-6 -->
      <div class="col-md-6 col-lg-4"  data-aos-delay="600">
        <div class="box">
          <span class="fa fa-poll-h fa-2x"></span>
          <h3>After delivery support</h3>
          <p>I'm always attached to the products I create, so I will be available to fix software bugs after delivery. </p>
        </div>
      </div>
    </div>
  </div>
</section>
