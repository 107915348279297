import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { TopSectionComponent } from './components/top-section/top-section.component';
import { AboutComponent } from './components/about/about.component';
import { ServicesSectionComponent } from './components/services-section/services-section.component';
import { CounterComponent } from './components/counter/counter.component';
import { ResumeComponent } from './components/resume/resume.component';
import { ProjectsComponent } from './components/projects/projects.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { ContactComponent } from './components/contact/contact.component';
import { SkillsComponent } from './components/skills/skills.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {ProjectPagesModule} from './pages/project-pages/project-pages.module';
import { HomePageComponent } from './pages/home-page/home-page.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    TopSectionComponent,
    AboutComponent,
    ServicesSectionComponent,
    CounterComponent,
    ResumeComponent,
    ProjectsComponent,
    TestimonialsComponent,
    ContactComponent,
    SkillsComponent,
    HomePageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ProjectPagesModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
