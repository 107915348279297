import { Component, OnInit } from '@angular/core';

declare var $: any
@Component({
  selector: 'app-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.css']
})
export class CounterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('.counter').counterUp({
      delay: 10,
      time: 2000
    });
  }

}
