import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ElbaladiyaComponent} from './elbaladiya/elbaladiya.component';
import {HackoverComponent} from './hackover/hackover.component';
import {JciComponent} from './jci/jci.component';
import {MedextractComponent} from './medextract/medextract.component';
import {PentboxComponent} from './pentbox/pentbox.component';
import {PfaComponent} from './pfa/pfa.component';
import {PortfolioComponent} from './portfolio/portfolio.component';
import {WebipieComponent} from './webipie/webipie.component';
import {ProjectPagesComponent} from './project-pages.component';
import {NimgameComponent} from './nimgame/nimgame.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectPagesComponent,
    children:
    [
      {
        path: 'elbaladiya',
        component: ElbaladiyaComponent
      },
      {
        path: 'hackover',
        component: HackoverComponent
      },
      {
        path: 'jci',
        component: JciComponent
      },
      {
        path: 'medextract',
        component: MedextractComponent
      },
      {
        path: 'nimgame',
        component: NimgameComponent
      },
      {
        path: 'pentbox',
        component: PentboxComponent
      },
      {
        path: 'pfa',
        component: PfaComponent
      },
      {
        path: 'portfolio',
        component: PortfolioComponent
      },
      {
        path: 'webipie',
        component: WebipieComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectPagesRoutingModule { }
