<section class="projects" id="projects">
  <div class="container">
    <h2 class="text-center" >Projects</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/elbaladiya">
          <div class="image">
            <img src="../../../assets/images/projects/elbaladiya.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="#">elBaladiya.tn</a>
            <span class="see-link">Visit</span></h3>
            <i>09/2020 - 04/2021</i>
            <p>This project is done during my Part Time job with elBaladiya.tn, it was an astonishing experience</p>

            <div class="project-tag">Angular</div>
            <div class="project-tag">Git / Gitlab</div>
          </div>
        </div>
      </div>
      <!-- Post-2 -->
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/medextract">
          <div class="image">
            <img src="../../../assets/images/projects/medextract.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="">MedExtract</a></h3>
            <i>01/2021 - 04/2021</i>
            <p>Me and my teammates were able to create a SaaS application that classifies medical research papers, during our final year project</p>
            <div class="project-tag">Angular</div>
            <div class="project-tag">Django</div>
          </div>
        </div>
      </div>
      <!-- Post-3 -->
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/hackover">
          <div class="image">
            <img src="../../../assets/images/projects/hackover.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="">HackOver</a></h3>
            <i>07/2020 - 09/2020</i>
            <p>During my internship at Halber.io, I was able to create web platform to manage Hackathons in Tunisia.</p>
            <div class="project-tag">React</div>
            <div class="project-tag">NodeJS</div>
          </div>
        </div>
      </div>
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/webipie">
          <div class="image">
            <img src="../../../assets/images/projects/webipie.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="">Webipie</a></h3>
            <i>09/2020 - 02/2021</i>
            <p>Webipie is a newly created startup. I participated at designing and developing their website builder that will be soon in production.</p>
            <div class="project-tag">Angular</div>
          </div>
        </div>
      </div>
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/pfa">
          <div class="image">
            <img src="../../../assets/images/projects/pfa.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="">PFA Management Platform</a></h3>
            <i>01/2021 - 03/2021</i>
            <p>This project offers the possibility of managing the end of study projects for final year students and for professors.</p>
            <div class="project-tag">Angular</div>
            <div class="project-tag">NestJS</div>
          </div>
        </div>
      </div>
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/pentbox">
          <div class="image">
            <img src="../../../assets/images/projects/pentbox.jpg" alt="">
            <a href="" class="cate">Cryptology</a>
          </div>
          <div class="text">
            <h3><a href="">Pentbox-Python</a></h3>
            <i>01/2021 - 03/2021</i>
            <p>This project is a cryptography tool that allows encoding, decoding, hashing, cracking hashes and asymmetric and symmetric encryption/decryption.</p>
            <div class="project-tag">Python</div>
          </div>
        </div>
      </div>
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/nimgame">
          <div class="image">
            <img src="../../../assets/images/projects/nim.jpg" alt="">
            <a href="" class="cate">AI</a>
          </div>
          <div class="text">
            <h3><a href="">NIM Game</a></h3>
            <i>12/2020</i>
            <p>This project is a script that create a NIM game between the system and the user, and the winner will be announced at the end.</p>
            <div class="project-tag">Python</div>
          </div>
        </div>
      </div>
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/jci">
          <div class="image">
            <img src="../../../assets/images/projects/jci.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="">JCI Bekalta Website</a></h3>
            <i>03/2020 - 05/2020</i>
            <p>A static website for the junior chamber international of Bekalta that showcases the achievements of the association.</p>
            <div class="project-tag">Angular</div>
          </div>
        </div>
      </div>
      <div class="co-md-6 col-lg-4 mt-5">
        <div class="box"  routerLink="/projects/portfolio">
          <div class="image">
            <img src="../../../assets/images/projects/portfolio.jpg" alt="">
            <a href="" class="cate">Web</a>
          </div>
          <div class="text">
            <h3><a href="">My Portfolio</a></h3>
            <i>04/2021</i>
            <p>Using Angular, I was able to create my own portfolio to showcase my projects and career, through an interactive UI/UX.</p>
            <div class="project-tag">Angular</div>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>
