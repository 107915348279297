import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pentbox',
  templateUrl: './pentbox.component.html',
  styleUrls: ['./pentbox.component.css']
})
export class PentboxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
