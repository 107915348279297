<!-- ===== Start Slider ===== -->
<section class="slider d-flex justify-content-center" id="slider">
  <div class="container text-center" style="z-index: 50">
    <div class="row all-content">
      <div class="col-12">
        <div class="left-content">
          <div class="d-flex align-items-center justify-content-center mb-5"

               data-aos-offset="200">
            <div class="circled-photo"></div>
          </div>

          <p class="hello vivify fadeIn">
            HELLO I'M</p>
          <!-- Animated Text-->
          <div class="animated-headlines vivify fadeIn">
            <h1 class="ah-headline clip is-full-width" >
              <span class="ah-words-wrapper">
                <b class="is-visible">Yassine Gabsi</b>
                <b>Software Engineer</b>
                <b>Web Developer</b>
                <b>Frontend Developer</b>
                <b>UI/UX Designer</b>
              </span>
            </h1>
          </div>
          <p class="paragraph vivify fadeIn">
            Passionate about new technologies, very interested in the progress of the scientific world and the news in
            general.
          </p>
          <a href="#" class="btn vivify fadeIn" (click)="downloadMyFile()">
            Download CV</a>
        </div>
      </div>
    </div>
  </div>
  <div style="z-index: 10" id="particles-js" class="particels"></div>
</section>
<!-- ===== End Slider ===== -->

