<section class="contact" id="contact">
  <h2 class="text-center">Keep In Touch</h2>
  <div class="container"  >
    <div class="row">
      <div class="col-md-8">

        <form [formGroup]="form" method="post" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-sm-6">
              <input [formControl]="name" required type="text" class="form-control" placeholder="Name">
            </div>
            <div class="col-sm-6">
              <input [formControl]="email" required type="email" class="form-control" placeholder="Email">
            </div>
            <div class="col-sm-12">
              <input [formControl]="subject" required  type="text"  class="form-control" placeholder="Subject">
            </div>
          </div>
          <div class="form-group">
            <textarea [formControl]="message" maxlength="256" class="form-control" rows="5" id="comment" placeholder="Message"></textarea>
          </div>
          <input [formControl]="honeypot" class="hidden" type="text" />
          <button class="btn btn-block" [disabled]="isLoading" type="submit">Send Now!</button>
        </form>
      </div>
      <div class="col-md-4">
        <div class="info mt-3">
          <span class="fa fa-map-marker fa-lg contact-icon"></span>
          <p>Adress 1: Bekalta, Monastir, Tunisia, 5090
            <br>
            Street Bahri Brigui </p>

          <p>Adress 2: Cité Khadhra, Tunis, Tunisia, 1003
            <br>
             </p>

          <span class="fa fa-mobile fa-lg contact-icon"></span>
          <p>+216 50 404 916
            <br>
            <p></p>
          <span class="fa fa-at fa-lg contact-icon"></span>
          <p>gabsiyassine@gmail.com <br> yassine.gabsi@insat.u-carthage.tn</p>

        </div>
      </div>
    </div>
  </div>
</section>
