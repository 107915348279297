<section id="resume" class="resume-section">
  <h2 class="text-center font-weight-bold pt-4 vivify fadeIn delay-150" style="color: var(--blue-color);">Career</h2>
  <div class="resume container">
    <div class="resume_right">
      <div class="row">
        <div class="col-md-6">
          <div class="resume_item resume_work">
            <div class="title">
              <p class="bold" >Work Experience</p>
            </div>
            <ul>
              <li>
                <div class="info">
                  <p class="semi-bold" >Software Engineering Intern</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Expat Housing Networks EHN</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >06/2021 - Present</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Amsterdam, Netherlands ( Remotely )</i>

                  <div class="mx-2 mt-4"> • Helping in developing a Customer relationship management ( CRM ) platform using Laravel and ReactJS.</div>
                  <div class="mx-2 mt-1"> • Developing a Gantt chart to display the work overload for the clients.</div>
                  <div class="mx-2 mt-1"> • Working on improving EHN official website User Interface.</div>
                  <div class="mx-2 mt-1"> • Helping in the development of the admin dashboard for the EHN official dashboard.</div>
                  <div class="mx-2 mt-1"> • Fixing UI/UX and frontend logic bugs on several projects.</div>
                  <div class="mx-2 mt-1"> • Working on fixing the responsiveness side of several projects.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Angular Frontend Developer Intern</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >FinGenesis</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >05/2021 - 06/2021</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Singapore city, Republic of Singapore ( Remotely )</i>

                  <div class="mx-2 mt-4"> • Helping the implementation of Abundance Dashboard to predict the best financial decisions for companies.</div>
                  <div class="mx-2 mt-1"> • Creating the graphic chart for the Abundance Dashboard.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Fullstack Web Developer</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >BECOME Technology</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >03/2021 - 05/2021</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Paris, France ( Remotely )</i>

                  <div class="mx-2 mt-4"> • Implemented a PDF Generation project using NodeJS and Firebase.</div>
                  <div class="mx-2 mt-1"> • Created a PWA Application using ReactJS.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Lead Frontend Developer</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >elBaladiya.tn</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >09/2020 - Present</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Sfax, Tunisia ( Remotely )</i>

                  <div class="mx-2 mt-4"> • Fixed UI/UX and frontend logic bugs.</div>
                  <div class="mx-2 mt-1"> • Implemented new UI Design using Angular 11.</div>
                  <div class="mx-2 mt-1"> • Worked on maintaining both Admin Dashboard and Citizen Dashboard.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >MERN Stack Intern</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Halber.io</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >07/2020 - 09/2020</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Monastir, Tunisia</i>

                  <div class="mx-2 mt-4"> • Designed the UI/UX of the HackOver project using Adobe XD.</div>
                  <div class="mx-2 mt-1"> • Integrated the design using ReactJS.</div>
                  <div class="mx-2 mt-1"> • Implemented the backend using NodeJS and MongoDB.</div>
                </div>
              </li>


            </ul>
          </div>
          <div class="resume_item resume_work">
            <div class="title">
              <p class="bold" >Achievements</p>
            </div>
            <ul>
              <li>
                <div class="info">
                  <p class="semi-bold" >First Prize FST Hackathon Web Development</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >IEEE FST</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >01/2020</i>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Best JCI Bekalta Member</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Junior Chamber International Bekalta</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >12/2019</i>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Best JCI Bekalta New Member</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Junior Chamber International Bekalta</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >12/2018</i>
                </div>
              </li>

            </ul>
          </div>

        </div>
        <div class="col-md-6">
          <div class="resume_item resume_education">
            <div class="title">
              <p class="bold" >Education</p>
            </div>
            <ul>
              <li>
                <div class="info">
                  <p class="semi-bold" >Software Engineering Degree</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >National Institute of Applied Science and Technology</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >09/2017 - Present</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Tunis, Tunisia</i>
                </div>
              </li>
            </ul>
          </div>
          <div class="resume_item resume_education">
            <div class="title">
              <p class="bold" >Volunteer Experience</p>
            </div>
            <ul>
              <li>
                <div class="info">
                  <p class="semi-bold" >Vice President in charge of Development and Learning</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Junior Chamber International Bekalta</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >01/2020 - 12/2020</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Monastir, Tunisia</i>

                  <div class="mx-2 mt-4"> • Helped team members to develop their soft and hard skills.</div>
                  <div class="mx-2 mt-1"> • Prepared +40 training sessions about soft and hard skills for team members.</div>
                  <div class="mx-2 mt-1"> • Participated in several social actions with the association.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Manager</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >International Institute of Debate Bekalta ( IIDebate )</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >01/2020 - 04/2021</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Monastir, Tunisia</i>

                  <div class="mx-2 mt-4"> • Participated in several social actions.</div>
                  <div class="mx-2 mt-4"> • Participated in creating the graphic design.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Co-Founder</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >BIL:Bekalta</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >01/2019 - 09/2019</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Monastir, Tunisia</i>

                  <div class="mx-2 mt-4"> • Participated in the preparation of the BIL Conference.</div>

                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Head Designer</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Tunisian Aeroday</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >09/2019 - 09/2020</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Tunis, Tunisia</i>

                  <div class="mx-2 mt-4"> • Created the Graphical charter for the association.</div>
                  <div class="mx-2 mt-1"> • Participated in the preparation of the event.</div>
                  <div class="mx-2 mt-1"> • Managed a team of designers.</div>
                </div>
              </li>

              <li>
                <div class="info">
                  <p class="semi-bold" >Webmaster Team Member</p>
                  <br>
                  <i class="mx-2 fas fa-map-marker-alt resume-tag"></i>
                  <i style="font-size: 17px;" class="resume-tag" >Tunirobots</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-stopwatch mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >04/2020 - 08/2020</i>

                  <br>
                  <i class=" resume-tag mx-2 fas fa-globe-americas mt-3"></i>
                  <i style="font-size: 17px" class="resume-tag" >Tunis, Tunisia</i>

                  <div class="mx-2 mt-4"> • Participated in the creation of the association's website.</div>

                </div>
              </li>
            </ul>
          </div>

        </div>
      </div>

    </div>
  </div>
</section>
