import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  form: FormGroup;
  name: FormControl = new FormControl('', [Validators.required]);
  email: FormControl = new FormControl('', [Validators.required, Validators.email]);
  subject: FormControl = new FormControl('', [Validators.required]);
  message: FormControl = new FormControl('', [Validators.required, Validators.maxLength(256)]);
  honeypot: FormControl = new FormControl(''); // we will use this to prevent spam
  submitted = false; // show and hide the success message
  isLoading = false; // disable the submit button if we're loading
  responseMessage: string | undefined; // the response message to show to the user
  constructor(private formBuilder: FormBuilder, private http: HttpClient) {
    this.form = this.formBuilder.group({
      name: this.name,
      email: this.email,
      subject: this.subject,
      message: this.message,
      honeypot: this.honeypot
    });
  }

  ngOnInit(): void {
  }

  onSubmit(): void {
    console.log(this.form.status);
    if (this.form.status !== 'VALID') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Please verify the form content',
      });
    }
    if (this.form.status === 'VALID' && this.honeypot.value === '') {
      this.form.disable(); // disable the form if it's valid to disable multiple submissions
      const formData: any = new FormData();
      // @ts-ignore
      formData.append('name', this.form.get('name').value);
      // @ts-ignore
      formData.append('email', this.form.get('email').value);
      // @ts-ignore
      formData.append('message', this.form.get('message').value);
      // @ts-ignore
      formData.append('subject', this.form.get('subject').value);
      this.isLoading = true; // sending the post request async so it's in progress
      this.submitted = false; // hide the response message on multiple submits
      this.http.post('https://script.google.com/macros/s/AKfycbxUc0TUvvC_2vNTOkIsv3BOk35qzF_5yMbupSDhZ1gh527Yd3T3cVLDYRMmkC5M1Jra/exec',
        formData).subscribe(
        (response) => {
          // choose the response message
          // @ts-ignore
          if (response['result'] === 'success') {
            Swal.fire({
              icon: 'success',
              title: 'Thank You!',
              text: 'Thanks for the message! I\'ll get back to you soon!',
            });
          } else {
            Swal.fire({
              icon: 'success',
              title: 'Oops...',
              text: 'Oops! Something went wrong... Reload the page and try again.',
            });
          }
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(response);
        },
        (error) => {
          Swal.fire({
            icon: 'success',
            title: 'Oops...',
            text: 'Oops! Something went wrong... Reload the page and try again.',
          });
          this.form.enable(); // re enable the form after a success
          this.submitted = true; // show the response message
          this.isLoading = false; // re enable the submit button
          console.log(error);
        }
      );
    }
  }

}
