<section class="blog" id="blog">
  <div class="container">
    <h2 class="text-center">End of Studies projects management application</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="col-12">
        <div class="box">
          <div class="image">
            <!--            <img src="../../../../assets/images/projects/elbaladiya.jpg" alt="">-->
            <!--            <a href="" class="cate">Web</a>-->
            <h3 style="padding: 0 20px">
              <a href="https://github.com/YassineGabsi/INSAT-PFE-Frontend-Admin" target="_blank" class="see-link"
                 style="font-size: 20px">Visit</a>
            </h3>
          </div>
          <div class="text">
            <h3><span>End of Studies projects management application</span>
              <!--              <span class="see-link">Visit</span>-->
            </h3>
            <i>01/2021 - 03/2021</i>
            <br>
            <br>

            <div class="col-md-8 mx-auto mb-5">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/pfa1.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/pfa2.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/pfa3.jpg" alt="First slide">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

            </div>

            <p>This Project is a web application that offers several services for end of study students, aiming to
              digitalize the communication between professors and students.
              <br>
              <br>
              In this project I had the chance to:
              <br>
              <br>
              • Participate in implementing the Frontend application.
              <br>
              • Participate in creating the Backend side.
              <br>
              • Create an interactive and user-friendly UI/UX.
              <br>
            </p>


            <div class="project-tag">Angular</div>
            <div class="project-tag">NestJS</div>
            <div class="project-tag">Git / Github</div>
            <div class="project-tag">CSS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
