<section class="blog" id="blog">
  <div class="container">
    <h2 class="text-center">Hackover</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="col-12">
        <div class="box">
          <div class="image">
            <!--            <img src="../../../../assets/images/projects/elbaladiya.jpg" alt="">-->
            <!--            <a href="" class="cate">Web</a>-->
            <h3 style="padding: 0 20px">
              <a href="https://github.com/YassineGabsi/HackOver-Front" target="_blank" class="see-link" style="font-size: 20px">Visit</a>
            </h3>
          </div>
          <div class="text">
            <h3><span>Hackover</span>
              <!--              <span class="see-link">Visit</span>-->
            </h3>
            <i>07/2020 - 09/2020</i>
            <br>
            <br>

            <div class="col-md-8 mx-auto mb-5">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/hackover1.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/hackover2.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/hackover3.jpg" alt="First slide">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

            </div>

            <p>Hackover is a Hackathons management web application. It aims to be the first tunisian Hackathons platform,
              through the services that it provides for both participants and organisers.
              <br>
              <br>
              The participant has the right to participate, comment, consult, and rate a specific hackathons. Also
              he can consult his participation history.
              <br>
              So during my internship with Halber.io, I worked on this project, and I accomplished these tasks:
              <br>
              • Modeling the application using UML principles
              <br>
              • Prototyping the application using Adobe XD and Adobe Photoshop.
              <a href="https://xd.adobe.com/view/1229a113-cc07-446c-9e29-19ab08efc283-477a/" target="_blank"><b>(Click here to see prototype)</b></a>
              <br>
              • Implement the prototype in a react application, using SASS as css preprocessor.
              <br>
              • Creating the backend application using NodeJS and MongoDB as a database.
              <br>
              • Linking both Frontend and Backend parts.
              <br>
            </p>


            <div class="project-tag">React</div>
            <div class="project-tag">NodeJS</div>
            <div class="project-tag">Git / Github</div>
            <div class="project-tag">Sass</div>
            <div class="project-tag">Adobe XD</div>
            <div class="project-tag">Adobe Photoshop</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
