<section class="about-me" id="about">
  <div class="container">
    <div class="row">
      <div class="col-md-5">
        <img src="../../../assets/images/yassine2.png" alt="" class="img-fluid">
      </div>
      <div class="col-md-7">
        <h2 style="margin-left: -20px">
          <i class="fas fa-fingerprint"></i>
          About Me</h2>
        <div class="infos-section mt-3">
          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span class=" mx-2 info-tag">Website: </span>
            <span>www.yassinegabsi.com</span>
          </div>

          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span class=" mx-2 info-tag">Phone: </span>
            <span>+216 50 404 916</span>
          </div>

          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span class=" mx-2 info-tag">City: </span>
            <span>Monastir, Tunisia</span>
          </div>

          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span class=" mx-2 info-tag">Degree: </span>
            <span>Software engineering Degree</span>
          </div>

          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span class=" mx-2 info-tag">First Email: </span>
            <span>gabsiyassine@gmail.com</span>
          </div>

          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span class=" mx-2 info-tag">Second Email: </span>
            <span>yassine.gabsi@insat.u-carthage.tn</span>
          </div>

          <div class="col-12 mt-3">
            <i class="fas fa-angle-double-right fa-lg" style="color: white"></i>
            <span style="margin-top: -10px" class=" mx-2 info-tag">Get In Touch: </span>
            <a href="https://www.linkedin.com/in/yassine-gabsi/" target="_blank"  class="mx-2"><i class="fab fa-linkedin fa-2x social-icon"></i></a>
            <a href="https://github.com/YassineGabsi" target="_blank" class="mx-2"><i class="fab fa-github-square fa-2x social-icon"></i></a>
            <a href="https://www.facebook.com/Weld.LGabsi/" target="_blank" class="mx-2"><i class="fab fa-facebook-square fa-2x social-icon"></i></a>
            <a href="https://www.instagram.com/yassine.gaabsi/" target="_blank" class="mx-2"><i class="fab fa-instagram-square fa-2x social-icon"></i></a>
            <a href="https://www.upwork.com/freelancers/~01451619dcad9faf82?viewMode=1" target="_blank" class="mx-2" >
                <img  class="img-fluid social-icon upwork-icon"
                     src="../../../assets/images/logos/upwork2.png" alt=""></a>
          </div>
        </div>
       <div class="mt-5">
         <div style="display: inline-block" href="#" class="btn-1" (click)="downloadMyFile()">Download CV</div>
         <a style="display: inline-block" href="#projects" class="btn-2">Portfolio</a>
       </div>

      </div>
    </div>
  </div>
</section>
