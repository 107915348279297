<section class="blog" id="blog">
  <div class="container">
    <h2 class="text-center">elBaladiya.tn</h2>
    <div class="row">
      <!-- Post-1 -->
      <div class="col-12">
        <div class="box">
          <div class="image">
<!--            <img src="../../../../assets/images/projects/elbaladiya.jpg" alt="">-->
<!--            <a href="" class="cate">Web</a>-->
            <h3 style="padding: 0 20px">
              <a href="https://www.elbaladiya.tn/" target="_blank" class="see-link" style="font-size: 20px">Visit</a>
            </h3>
          </div>
          <div class="text">
            <h3><span>elBaladiya.tn</span>
<!--              <span class="see-link">Visit</span>-->
            </h3>
            <i>09/2020 - 04/2021</i>
            <br>
            <br>

            <div class="col-md-8 mx-auto mb-5">
              <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                <ol class="carousel-indicators">
                  <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                  <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                </ol>
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/elbaladiya1.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/elbaladiya2.jpg" alt="First slide">
                  </div>
                  <div class="carousel-item">
                    <img class=" img-fluid w-100" src="../../../../assets/images/projects/elbaladiya3.jpg" alt="First slide">
                  </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="sr-only">Next</span>
                </a>
              </div>

            </div>

            <p>elBaladiya.tn is a social project that aims to digitalize the Tunisian administrations,
              especially the municipalities. This application offers several services for citizens so they can
              maintain a valuable connection between them and the municipality members.
              <br>
              <br>
              So I had the chance to be a part of the technical team as a Frontend Developer using Angular, and I was able
              to accomplish several tasks:
              <br>
              <br>
              • Fixing UX/UI bugs in the application
              <br>
              • Implementing new features.
              <br>
              • Improving and maintaining existing features.
              <br>
              • Bringing a new UI/UX design for the application into life.
              <br>
            </p>


            <div class="project-tag">Angular</div>
            <div class="project-tag">Git / Gitlab</div>
            <div class="project-tag">CSS</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
