<link rel="stylesheet" href="testimonials.component.css">
<section class="testimonials" id="testimonials">
  <div class="container text-center">
    <h2>Testimonials</h2>
    <div class="row text-center">
      <div class="col-md-12">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
          <!-- Indicators-->
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
          </ol>

          <div class="carousel-inner">
            <!-- Item-1 -->
            <div class="carousel-item active text-center">
              <img style="max-height: 70px" src="../../../assets/images/testimonials.png" alt="" class="colon">
              <br>
              <i>"J'ai le plaisir de travailler avec Yassine, sur des projets web full-stack.
                Je le recommande vivement pour son investissement, sa pro activité et ses compétences."</i>
              <br>
              <img src="../../../assets/images/testimonials/david.jpg" alt="" class="center-block team">
              <h3>David Nizard</h3>
              <h4>CEO at BECOME Technology, Paris, France</h4>
              <a href="https://fr.linkedin.com/in/david-nizard" target="_blank" style="color: #ffffff;" class="mx-2"><i
                class="fab fa-linkedin fa-2x social-icon"></i></a>
            </div>

            <!-- Item-2 -->
            <div class="carousel-item text-center">
              <img style="max-height: 70px" src="../../../assets/images/testimonials.png" alt="" class="colon">
              <br>
              <i>“I had the pleasure to work alongside Yassine for the great part of 6
                months. <br> If you are willing to hire an organized, competent, and
                proactive developer, then Yassine Gabsi is the man for the job. <br> His
                valued contributions to elBaladiya.tn will always remain impactful.”</i>
              <br>
              <img src="../../../assets/images/testimonials/rami.jpg" alt="" class="center-block team">
              <h3>Rami Hachicha</h3>
              <h4>President at Tounes Lina</h4>
              <a href="https://de.linkedin.com/in/rami-hachicha" target="_blank" style="color: #ffffff;" class="mx-2"><i
                class="fab fa-linkedin fa-2x social-icon"></i></a>
            </div>
            <!-- Item-3 -->
            <div class="carousel-item text-center">
              <img style="max-height: 70px" src="../../../assets/images/testimonials.png" alt="" class="colon">
              <br>
              <i>"I've been involved in several projects with Yassine, mainly Webipie and MedExtract.
                <br>
                I'm really fascinated by his great design and web development skills. He created appealing, high quality
                and industry-ready web applications.
                <br>
                With his talent, he clearly stands out among other web developers."
              </i>
              <br>
              <img src="../../../assets/images/testimonials/ala.jpg" alt="" class="center-block team">
              <h3>Alaeddine Abdesslem</h3>
              <h4>Backend Engineer at Alternative Data Group, NY, USA</h4>
              <a href="https://www.linkedin.com/in/alaeddine-abdessalem-549b65169/" style="color: #ffffff;"
                 class="mx-2"><i class="fab fa-linkedin fa-2x social-icon"></i></a>
              <a href="https://alaeddineabdessalem.com/" target="_blank" style="color: #ffffff;" class="mx-2"><i
                class="fas fa-globe-americas fa-2x social-icon"></i></a>
              <a href="https://www.upwork.com/fl/alaeddineabdessalem" target="_blank" style="color: #ffffff;"
                 class="mx-2">
                <img class="img-fluid social-icon" style="max-height: 35px; margin-top: -14px"
                     src="../../../assets/images/logos/upwork.png" alt=""></a>
            </div>

            <div class="carousel-item text-center">
              <img style="max-height: 70px" src="../../../assets/images/testimonials.png" alt="" class="colon">
              <br>
              <i>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
                porttitordapibus dictum.<br>
                Fusce faucibus ligula scelerisque, eleifend turpis in</i>
              <br>
              <img src="../../../assets/images/testimonials/nour.jpg" alt="" class="center-block team">
              <h3>Nour Karoui</h3>
              <h4>CEO at Webipie</h4>
              <span style="color: #ffffff;" class="mx-2"><i class="fab fa-linkedin fa-2x social-icon"></i></span>
            </div>

          </div>
          <a
            class="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev">
            <span class="fa fa-angle-left icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next">
            <span class="fa fa-angle-right icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
