<footer class="footer text-center" >
  <div class="social-icons-footer" >
    <a href="https://www.linkedin.com/in/yassine-gabsi/" target="_blank" class="mx-2"><i class="fab fa-linkedin fa-2x social-icon"></i></a>
    <a href="https://github.com/YassineGabsi" target="_blank" class="mx-2"><i class="fab fa-github-square fa-2x social-icon"></i></a>
    <a href="https://www.facebook.com/Weld.LGabsi/" target="_blank" class="mx-2"><i class="fab fa-facebook-square fa-2x social-icon"></i></a>
    <a href="https://www.instagram.com/yassine.gaabsi/" target="_blank" class="mx-2"><i class="fab fa-instagram-square fa-2x social-icon"></i></a>
    <a href="https://www.upwork.com/freelancers/~01451619dcad9faf82?viewMode=1" target="_blank" style="color: #ffffff;" class="mx-2">
                <img class="img-fluid social-icon" style="max-height: 32px; margin-top: -14px"
                     src="../../../assets/images/logos/upwork.png" alt=""></a>

  </div>
  <p style="font-weight: bold">All Rights Reserved ® 2021 - Yassine Gabsi</p>
</footer>
