import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})
export class SkillsComponent implements OnInit {

  animated = false;
  constructor() { }

  ngOnInit(): void {
    $(window).on('scroll', () => {
      if ($(document ).scrollTop() >= $('#skills').offset().top - 700 && !this.animated) {
        this.animated = true;
        $('.progress-bar-js').animate({
          width: '90%'
        }, 2500);

        $('.progress-bar-angular').animate({
          width: '90%'
        }, 2500);

        $('.progress-bar-react').animate({
          width: '80%'
        }, 2500);

        $('.progress-bar-node').animate({
          width: '80%'
        }, 2500);

        $('.progress-bar-nest').animate({
          width: '60%'
        }, 2500);

        $('.progress-bar-django').animate({
          width: '55%'
        }, 2500);

        $('.progress-bar-python').animate({
          width: '75%'
        }, 2500);

        $('.progress-bar-java').animate({
          width: '75%'
        }, 2500);

        $('.progress-bar-c').animate({
          width: '65%'
        }, 2500);

        $('.progress-bar-mongo').animate({
          width: '70%'
        }, 2500);

        $('.progress-bar-git').animate({
          width: '80%'
        }, 2500);

        $('.progress-bar-firebase').animate({
          width: '60%'
        }, 2500);

        $('.progress-bar-css').animate({
          width: '90%'
        }, 2500);

        $('.progress-bar-xd').animate({
          width: '80%'
        }, 2500);
      }
    });
  }

}
